import {onCLS, onFID, onLCP} from 'web-vitals';

function getNavigationMetrics(){
  let result = []
  try {
    result = window.performance.getEntriesByType("navigation")
  } catch (error) {
    console.error(error);
  }
  return result
}

/**
 * logToServer
 * 
 * Function to log the web vitals to the server using fetch or navigator.sendBeacon
 * it should also include the user agent and the current url
 */
async function logToServer(analyticsEvent) {
  try {
    analyticsEvent['userAgent'] = navigator.userAgent;
    analyticsEvent['url'] = window.location.href;

    if(analyticsEvent['name'] === "LCP"){
      analyticsEvent['navigation'] = getNavigationMetrics();
    }

    const url = '/webvitals/';

    const options = {
      method: 'POST',
      body: JSON.stringify(analyticsEvent),
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error(`Failed to log web vitals to server: ${response.status} ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error(error);
  }
}

onCLS(logToServer);
onFID(logToServer);
onLCP(logToServer);
